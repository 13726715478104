import React from "react"
import PhotoGallery from "../../components/photogallery/PhotoGallery"
import Layout from "../../components/layout/Layout"
import { getImageNodes } from "../../lib"
import { graphql } from "gatsby"

export default function Travel({ data }) {
  const IMAGES = getImageNodes(data)
  return (
    <Layout title="Travel and Street" heading="Travel and Street">
      <PhotoGallery photos={IMAGES} />
    </Layout>
  )
}

export const query = graphql`
  query TravelAndStreet {
    allFile(filter: { relativeDirectory: { eq: "travel-and-street" } }) {
      edges {
        node {
          name
          base
          publicURL
        }
      }
    }
  }
`
